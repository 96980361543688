const de = {
  hero: {
    title: "Hero",
    header: "%% Digitale Werbung%%, die die Umwelt schont!",
    content: `Schön, dass Sie hier sind! Egal, ob Sie zufällig oder bewusst auf dieser Seite gelandet sind: Hier sind Sie richtig!

  Ob Sonntagsanzeiger, Plakatwand oder digitale Anzeige: Werbung ist ressourcenintensiv.Als Digitalvermarkter übernehmen wir Verantwortung für unsere Produkte und ihre Effekte.Wir wollen digitale Werbung nachhaltiger machen.Unser Ergebnis? Die %% eco ads®%%.

  Erfahren Sie jetzt mehr über die CO2 - reduzierten Werbemitteln der iq digital.`,
    image: "undraw_online_ad_mg4t.svg"
  },
  view1: {
    title: "reduceEmission",
    header: "Schon gewusst?",
    content: `Die Auslieferung digitaler Werbung verbraucht Strom und verursacht entsprechend CO2- Emissionen.

 Ein Beispiel: Eine durchschnittliche digitale Mediakampagne verursacht vergleichbare Treibhausgasmengen wie ein Flug von Düsseldorf nach Hawaii - und wieder zurück.

 Tatsächlich entspricht der Anteil der gesamten Medienbranche an den globalen Gesamtemissionen 3 bis 4 Prozent und liegt damit auf dem gleichen Niveau des weltweiten Flugverkehrs.

 Um den CO2 - Fußabruck von Mediakampagnen deutlich zu reduzieren, haben wir die %% eco ads®%% entwickelt.`,
    image: "undraw_Nature_benefits_re_kk70.svg"
  },
  view2: {
    title: "reduce",
    header: "%%eco ads®%% reduzieren die emittierte Menge CO2 pro Werbeausspielung.",
    paragraph1: "Wir haben an allen Ecken und Enden optimiert und signifikante CO2-Einsparungen erreicht:",
    paragraph2: "sind eingebettet in ein CO2-optimiertes technologisches Setup.",
    paragraph3: "iq digital setzt sich dabei nicht nur für den Klimaschutz ein, sondern hat darüber hinaus auch stets soziale und gesellschaftliche Aspekte im Blick. Dafür haben wir im August 2024 von Eco Vadis die Bronzemedaille verliehen bekommen.",
    li1: "haben ein deutlich geringeres Dateigewicht - je nach Werbemittel bis zu 84 % weniger.",
    li2: "sind auf ein notwendiges Maß an Trackings reduziert. Beim Tracking eines Werbemittels wird mithilfe spezieller Technologien verfolgt, wie viele Personen u.a. die Werbung gesehen oder angeklickt haben. Bei unseren eco ads® wird nur das gemessen, was absolut essenziell ist. Wir vermeiden lange Skripte, reduzieren Daten und sparen Energie.",
  },
  view5: {
    title: "why",
    header: "Was du tun kannst, um Nachhaltigkeit in der Digitalwerbung zu unterstützen?",
    content: "Jedes Mal, wenn Sie eine digitale Werbung sehen, die das %%eco ad®%% Label trägt, können Sie sich sicher sein: Das werbende Unternehmen übernimmt ökologische Verantwortung! \n Sie wollen sich auch selbst stärker einbringen? Setzen Sie auf digitale Medien, die qualitativ hochwertigen Content anbieten. Diese erkennen Sie daran, dass sie nicht mit Werbung überladen sind und eine entsprechende journalistische Qualität aufweisen.",
  },
  contact: {
    title: "Kontaktieren Sie uns.",
    header: "Sie haben eine Werbebotschaft, die Sie digital verbreiten möchten?",
    content: `Mit unseren %% eco ads®%% können Sie Ihre Werbebotschaft deutlich nachhaltiger vermarkten - crossdigital in einem Value Media Umfeld.Und das, ohne auf gestalterische Freiheit zu verzichten.

 %% eco ads®%% sind auch ökonomisch eine sinnvolle Alternative zu herkömmlichen Werbemitteln: Das Pricing ist mindestens genauso überzeugend wie die Qualität.

 Haben wir Ihr Interesse geweckt ? Wir beraten Sie gerne!`,
    button1Link: "https://www.iqdigital.de/service/kontakt/iq-digital/",
    button1Text: "Kontakt",
    button2Link: "/files/eco_ad_by_iq_digital.pdf",
    button2Text: "Mehr Informationen",
    button3Link: "https://www.iqdigital.de/Produkte/Digital/werbemittel-showroom",
    button3Text: "Werbemittel-Showroom",
  },
  footer: {
    content: `Wir von iq digital wollen mit eco ads® den digitalen Werbemarkt revolutionieren.
 Statt überladener Werbeformen haben wir die Vision, mit einem reduzierten Ansatz eine Win-Win-Situation für Kunde und User zu schaffen.
 Ganz nebenbei wollen wir Ihnen dabei helfen, Ihren digitalen ökologischen Fußabdruck zu reduzieren.

 Wir setzen auf Transparenz und Nachhaltigkeit in all unseren Prozessen.
 Auch in unserer Kommunikation achten wir auf Nachhaltigkeit: Diese Seite ist auf minimale Dateigrößen optimiert und verzichtet auf jegliche Art von Analytics.
 Außerdem bezieht der Hosting-Server der Website seinen Strom zu 100% aus erneuerbaren Energien.`,
    lists: [
      {
        header: "eco ad®",
        items: [
          {
            link: "#view_reduceEmission",
            text: "Umwelt"
          },
          {
            link: "#view_reduce",
            text: "Einsparung"
          },
          {
            link: "#view_why",
            text: "Warum eco ad®?"
          },
        ]
      },
      {
        header: "Links",
        items: [
          {
            link: "https://www.iqdigital.de/",
            text: "iq digital"
          },
          {
            link: "/files/eco_ad_by_iq_digital.pdf",
            text: "eco ads® buchen"
          },
          {
            link: "https://www.iqdigital.de/Ueber-uns/Impressum/",
            text: "Impressum"
          },
          {
            link: "https://www.iqdigital.de/service/kontakt/iq-digital/",
            text: "Kontakt"
          },
        ]
      },
    ]
  }
};

export default de;